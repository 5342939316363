import React, { useState } from "react";

import { MapContainer, TileLayer } from "react-leaflet";
import { CustomMarker } from "../components/CustomMarker";

const orderDefaultdata = {
	orderTimestamp: "2024-12-17 14:00",
	Reason: "Spårarbete",
	TrainNumber: 2914,
	Type: "Pendel",
	BusesOrdered: 4,
	ScheduledDepartureTime: "2024-12-17 14:45",
	FromStation: "Täby Centrum",
	ToStation: "Stockholm Östra",
	OrderedBusesCount: 4,
	ViaStations: "Djursholms Ösby, Mörby",
	Comment: "",
	RegistrationNumber: "LMN456, LMN456, LMN456, LMN456",
	BusesArrived: 0,
	FirstBusArrivalApprox: "",
	ETA: "",
	ATA: "",
	ATD: "",
	EndTime: "",
};

function ReplacementTripOrderDetails() {
	const [order, setOrder]: any = useState(orderDefaultdata);
	// State for buses assigned to the order
	const [buses, setBuses] = useState([""]);

	// Handler to update bus registration numbers
	const handleBusChange = (index: number, value: any) => {
		const updatedBuses = [...buses];
		updatedBuses[index] = value;
		setBuses(updatedBuses);
	};

	// Add a new input for a bus
	const addBus = () => {
		setBuses([...buses, ""]);
	};

	// Remove a bus input
	const removeBus = (index: number) => {
		setBuses(buses.filter((_, i) => i !== index));
	};

	// Submit handler
	const handleSubmit = (e: any) => {
		e.preventDefault();
		// Logic to save bus assignments or update the database
		setBuses([...buses, ""]);
	};

	const tripData: any = null;

	return (
		<div className="container mx-auto px-4 py-8">
			<h1 className="text-2xl font-bold mb-4">Orderdetaljer</h1>
			<div className="bg-gray-100 rounded p-4 shadow">
				<p>
					<strong>Tidpunkt för beställning:</strong> {order.OrderTimestamp}
				</p>
				<p>
					<strong>Orsak:</strong> {order.Reason}
				</p>
				<p>
					<strong>Tågnr:</strong> {order.TrainNumber}
				</p>
				<p>
					<strong>Typ:</strong> {order.Type}
				</p>
				<p>
					<strong>Antal bussar beställt:</strong> {order.BusesOrdered}
				</p>
				<p>
					<strong>Beställd avgångstid:</strong> {order.ScheduledDepartureTime}
				</p>
				<p>
					<strong>Från:</strong> {order.FromStation}
				</p>
				<p>
					<strong>Till:</strong> {order.ToStation}
				</p>
				<p>
					<strong>VIA stationer:</strong> {order.ViaStations}
				</p>
				<p>
					<strong>Kommentar:</strong> {order.Comment}
				</p>
				<p>
					<strong>Antal bussar kom:</strong> {order.BusesArrived}
				</p>
				<p>
					<strong>Framkörd ca / Första bussen anländer:</strong>{" "}
					{order.FirstBusArrivalApprox}
				</p>
				<p>
					<strong>ETA:</strong> {order.ETA}
				</p>
				<p>
					<strong>ATA:</strong> {order.ATA}
				</p>
				<p>
					<strong>ATD:</strong> {order.ATD}
				</p>
				<p>
					<strong>Sluttid:</strong> {order.EndTime}
				</p>
			</div>

			<div className="flex flex-1 px-6 flex-col">
				<div className="flex flex-col w-3/4">
					<MapContainer
						center={
							tripData?.vehicleData.length
								? [
										tripData?.vehicleData[0]?.latitude,
										tripData?.vehicleData[0]?.longitude,
								  ]
								: [59.43926662897951, 18.084435863103256]
						}
						zoom={11}
					>
						<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					</MapContainer>
				</div>
			</div>
			<h2 className="text-xl font-bold mt-8">Lägg till bussar</h2>
			<form onSubmit={handleSubmit} className="mt-4">
				{buses.map((bus, index) =>
					index < order.BusesOrdered ? (
						<div key={index} className="flex items-center mb-2">
							<input
								type="text"
								className="border p-2 rounded w-full"
								placeholder={`Buss ${index + 1} registreringsnummer`}
								value={bus}
								onChange={(e) => handleBusChange(index, e.target.value)}
							/>
							<button
								type="button"
								className="ml-2 bg-white text-red-500 border border-red-500 px-4 py-2 rounded"
								onClick={() => removeBus(index)}
							>
								-
							</button>
						</div>
					) : null
				)}
				<button
					type="button"
					className="mt-4 bg-blue-100 text-blue-500 font-medium px-4 py-2 rounded mr-2"
					onClick={addBus}
				>
					Lägg till buss
				</button>
				{/* <button type="submit" className="mt-4 bg-green-100 font-medium text-green-500 px-4 py-2 rounded">
					Spara tilldelning
				</button> */}
			</form>
		</div>
	);
}

export default ReplacementTripOrderDetails;
