const allRoutes = [
	[
		"Trafikledning",
		[
			["parking", "Parkering"],
			["pincode", "Pinkod"],
			["replacementtrips", "Ersättningstrafik"],
			/* ["edu", "Utbildning", "Alpha"], */
/* 			["tva", "TVA", "Alpha"],
			["tlsimulator", "TL Simulator", "Alpha"], */
			/* 	["schedule", "Schedule", "Beta"], */
		],
	],
/* 	[
		"Resend",
		[
			["notfinishedtrips", "Ej slutförda turer"],
			["h19trips", "H19 turer"],
			["planneddeviated", "Planerade avvikelser"],
			["finedtrips", "Vitesbelagda turer"],
			["resent", "Eftersändningar"],
			["rerouted", "Ändrade hållplatser"],
			["reports", "Rapporter"],
			["trends", "Trender"],
			["status", "Status"],
		]
	], */
	[
		"Trafikanalys",
		[
/* 			["datafollowup", "Eftersändning"], */
/* 			["datamergevehicle", "DMV"],
			["data574", "DMW 574"], */
			/* ["tripanalyzer", "Turanalys", "Alpha"], */
/* 			["specialtrips", "Specialturer", "Alpha"], */
/* 			["genctsreport", "CTS-avvikelser"], */
			["bookinghelper", "Anropstrafikrapport"],
/* 			["apchelper", "APC-rapport"], */
			["createtimedistancegraph", "Roslagsbanan ST-graf"],
/* 			["createProductionReport", "Produktionsuppföljning"],
			["createMonthlyProductionReport", "Produktionsunderlag"], */
			/* ["trafficareareport", "Körtidsrapport Trafikområde"],
			["drivingreport", "Körtidsrapport Förare"],
/* 			["triptinder", "TripTinder"],
			["man-datafollowup", "Manuell datauppföljning"],
			["trip-merge", "Trip merger"],
			["tripprogresschecker", "Trip progress checker"],
			["filemerger", "Merge files"], */
		],
	],
	[
		"Info",
		[
			["infoPlaylists", "Spellistor",/*  "Beta" */],
			["infodisplays", "Skärmar",/*  "Beta" */],
			/* ["traffictech", "Trafikteknik", "Alpha"], */
		],
	],
	["Admin", [["users", "Användare"]]],
];

const routesMap: any = {
	apc: ["Trafikanalys", [["apchelper", "APC helper"]]],
	tdg: ["Trafikanalys", [["createtimedistancegraph", "TDG"]]],
	bp: [
		"Fordon",
		[
			["parking", "Parkering"],
			["pincode", "Pinkod"],
		],
	],
};

export const getNavigation = (user: {
	username: string;
	role: string;
	token: string;
	expires: string;
	firstname: string;
	lastname: string;
	permissions: string;
}) => {
	let res: (string | string[][])[][] = [];

	if (!user || user?.permissions === undefined) {
		return [];
	}

	if (user.role === "admin" || user.role === "god") {
		return [...allRoutes];
	} else {
		res = [];
	}

	let permissions = user?.permissions.split(" ");

	for (let i = 0; permissions.length > i; i++) {
		if (routesMap[permissions[i]]) {
			res = [...res, routesMap[permissions[i]]];
		}
	}

	return res;
};
