export const VASSDUPP_URL =
	process.env.NODE_ENV === "production"
		? "https://vassdupp.com/api/v1/"
		: "http://localhost:8787/api/v1/";

export const VASSDUPP_VERSION = "1.0.0";

export const XLSX_KEYS_TO_KEEP = [
	"Avtal",
	"AvtalsOmråde",
	"Period",
	"Trafikdygn",
	"DatedVehicleJourneyGid",
	"Tur",
	"Linje",
	"Riktning",
	"StartTid",
	"TLÅ",
	"Omlopp",
	"Fordon",
	"Kommentar",
	"Körd",
	"Enligt",
	"a",
	"b",
	"c",
	"d",
	"Planerad sträcka",
	"Planerad tid",
];

export const XLSX_KEYS_TO_KEEP_SNG = [
	"Trafikdygn",
	"Linje",
	"Tur",
	"StartTid",
	"Omlopp",
	"Fordon",
	"Kommentar",
];

export const XLSX_HEADERS = [
	"Avtal",
	"AvtalsOmråde",
	"Period",
	"Trafikdygn",
	"DatedVehicleJourneyGid",
	"Tur",
	"Linje",
	"Riktning",
	"StartTid",
	"TLÅ",
	"Omlopp",
	"Fordon",
	"Kommentar",
	"Körd",
	"Enligt",
	"Vilken information har inte levererats",
	"Varför har inte informationen levererats",
	"Har turen kört enligt plan? Om inte, vilka hållplatser trafikerades ej?",
	"Varför rapporterades ingen TLÅ?",
	"KM",
	"Tid",
];

export const NEW_XLSX_HEADRS = [
	"Datum",
	"Linje",
	"Tur",
	"Startid",
	"Omlopp",
	"Fordon",
	"SL avvikelse",
	"Fullständighet",
	"Körd",
	"Anledning till avvikelse",
	"Framtida åtgärd",
	"Kommentar",
];
