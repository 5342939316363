import { useEffect, useMemo, useState } from "react";

import { fetchVehicleDepotParking } from "../helper/network";
import DepotSelector from "../components/depotSelector";
import { RAMP_PRETTIER, convertVehiclePerArea } from "../helper/vehicles";
import usePersistentState from "../helper/usePersistentState";
import PINCodeCalculator from "../helper/pincode";
import busTypes from "../data/vehicles/bustypes";
import {
	DisplayBusRamps,
	getFuelLevelColor,
	getFuelLevelColorCSS,
	getTempLevelColor,
} from "../components/displayBusRamp";

export const DEPOT_FULL: any = {
	taga: "TÄGA",
	noga: "NOGA",
	maga: "MÄGA",
	gaga: "Gävle",
};

export const SELECT_ITEMS: any = {
	"taga": "Norrort",
	"-0": "0-",
	"noga": "Norrtälje",
	"-1": "0-",
	"maga": "Märsta",
	/* "maga": "MÄGA", */
	"-2": "0-",
	"gaga": "Gästrikland",
	/* "gaga": "Gävle", */
};

export default function BusParking() {
	const [depot, setDepot] = usePersistentState("selected_depot", "taga");
	const [vehicles, setVehicles] = useState<any>([]);
	const [showHelp, setShowHelp] = useState(false);
	const [viewMode, setViewMode] = useState<"overview" | "list">("overview");
	const [latestUpdate, setLatestUpdate] = useState(new Date().getTime());
	const [currentTime, setCurrentTime] = useState(new Date().getTime());

	// set interval to update time every second
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const fetchVehicles = async () => {
		try {
			const res = (await fetchVehicleDepotParking(depot)).data;

			setLatestUpdate(new Date().getTime());

			setVehicles((vehicles: any) => {
				if (vehicles.length !== 0) {
					// Find vehicles that have moved since last update.
					const movedVehicles = Object.keys(vehicles).filter(
						(vehicle) => vehicles[vehicle]?.ramp === res[vehicle]?.ramp
					);

					// Loop through moved vehicles and value to data.
					movedVehicles.map((vehicle) => {
						if (res[vehicle] !== vehicles[vehicle]) {
							res[vehicle].moved = true;
						}
					});
				}

				return res;
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchVehicles();
		const interval = setInterval(fetchVehicles, 30000);

		return () => {
			clearInterval(interval);
		};
	}, [depot]);

	return (
		<div className="flex flex-col flex-1 relative">
			<div className="flex flex-row justify-between align-top px-4 pt-2 relative">
				<div className="flex flex-row">
					<h1 className="font-bold text-2xl dark:text-stone-100">
						{RAMP_PRETTIER[SELECT_ITEMS[depot]] ?? SELECT_ITEMS[depot]}
					</h1>
					<span className="flex flex-row ml-2 items-center dark:text-stone-300">
						Tvättkod:{" "}
						<p className="font-semibold ml-1">
							{PINCodeCalculator.calculatePinCodeFromString(
								"666666" +
									new Date().toISOString().split("T")[0].replaceAll("-", "")
							)}
						</p>
					</span>
				</div>

				<div className="flex flex-row items-center">
					<p className="text-sm dark:text-stone-300 mr-2">
						Senast uppdaterad:{" "}
						{new Date(latestUpdate).toLocaleTimeString("sv-SE", {
							hour: "2-digit",
							minute: "2-digit",
							second: "2-digit",
						})}
					</p>
					<div className="w-24 h-2 dark:bg-stone-800 bg-stone-200 rounded-sm relative overflow-hidden">
						<div
							className="absolute h-full bg-blue-600"
							style={{
								width: `${(Math.abs(currentTime - latestUpdate) / 30000) * 100}%`,
							}}
						/>
					</div>
				</div>

				<div className="flex flex-row items-center">
					{"gavleborg" !== depot ? (
						<button
							className={
								"flex flex-row items-center px-3 rounded select-none font-semibold py-2 mr-1 " +
								(showHelp
									? "text-green-600 bg-green-100 dark:bg-green-800 dark:text-green-200"
									: "text-gray-300 bg-gray-100 dark:text-stone-300 dark:bg-stone-800")
							}
							onClick={() => setShowHelp(!showHelp)}
						>
							<p className="text-sm">Noob-mode{showHelp ? ": På" : ""}</p>
						</button>
					) : null}
					<button
						className={
							"flex flex-row items-center px-3 rounded select-none min-w-24 font-semibold py-2 mr-1 " +
							(viewMode === "overview"
								? "text-blue-600 bg-blue-100 dark:bg-blue-800 dark:text-blue-200"
								: "text-green-600 bg-green-100 dark:text-green-300 dark:bg-green-800")
						}
						onClick={() => setViewMode(viewMode === "overview" ? "list" : "overview")}
					>
						<p className="text-sm text-center w-full">
							{viewMode === "overview" ? "Översikt" : "Ramplista"}
						</p>
					</button>
					<DepotSelector
						placeholder="Område"
						value={depot}
						items={Object.entries(SELECT_ITEMS)}
						onChange={setDepot}
					/>
				</div>
			</div>

			<div className="flex flex-1">
				<BusRamps
					vehicles={vehicles}
					depot={depot}
					extraData={true}
					showHelp={showHelp}
					viewMode={viewMode}
				/>
			</div>
			<div className="self-center mt-auto flex flex-row">
				<p className="text-xs text-gray-500">Skapad av Artur Lindén</p>
			</div>
		</div>
	);
}

export const BusRamps = ({
	vehicles,
	depot,
	extraData,
	showHelp,
	viewMode,
}: {
	vehicles: any;
	depot: any;
	extraData: boolean;
	showHelp: boolean;
	viewMode: "overview" | "list";
}) => {
	if (!vehicles || vehicles?.length === 0) {
		return (
			<div className="flex flex-1 items-center justify-center h-full">
				<p className="my-auto">Laddar...</p>
			</div>
		);
	}

	if (viewMode === "list") {
		return <DisplayBusRamps vehicles={vehicles} depot={depot} extraData={true} />;
	}

	const vehicles_area = convertVehiclePerArea(vehicles, depot)

	return (
		<div className="flex flex-col mt-5 px-4">
			{vehicles_area.map((area: any) => (
				<div key={area[0]} className="flex flex-col mt-4">
					<h2 className="font-semibold text-xl text-stone-800 dark:text-stone-400">
						{area[0]}
					</h2>
					<div className="flex flex-row flex-1 flex-wrap justify-start gap-2">
						{area[1].map((item: any) => {
							return (
								<div
									key={area[0] + item.vehicle}
									className="text-center rounded font-semibold text-[22px] flex flex-row justify-between px-2 min-w-[114px] items-center bg-stone-100 dark:bg-stone-900  dark:text-stone-300"
								>
									<span className="flex flex-row justify-center items-center">
										<p className="flex">{item.vehicle}</p>
										{item.data.driverName !== null &&
										item.data.driverName !== "Ej inloggad" ? (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="10"
												height="10"
												viewBox="0 0 24 24"
												fill="currentColor"
												className="ml-2"
											>
												<path d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 18H4V4h16v16zM6 6h12v2H6V6zm0 4h12v2H6v-2zm0 4h5v2H6v-2z" />
											</svg>
										) : null}
										{showHelp ? (
											<p className="text-xs mt-[10px] items-end">
												{busTypes[item.vehicle]
													.split("-")[1]
													.substring(0, 1)
													.toUpperCase()}
											</p>
										) : null}
									</span>
									{item.data?.ramp.length < 3 && item.data?.ramp !== "-" ? (
										<p className="text-xs">{item.data?.ramp}</p>
									) : null}
									<div className="flex flex-col justify-center items-end">
										{item?.data?.fuel ? (
											<p
												className={
													"flex text-xs font-bold " +
													getFuelLevelColor(item?.data?.fuel)
												}
											>
												{parseInt(item?.data?.fuel)}{" "}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="7"
													height="7"
													viewBox="0 0 48 48"
													className="mt-[2px]"
													style={{
														fill: getFuelLevelColorCSS(
															item?.data?.fuel
														),
													}}
												>
													<path d="M0 0h48v48h-48z" fill="none" />
													<path d="M39.54 14.46l.03-.03-7.45-7.43-2.12 2.12 4.22 4.22c-1.88.72-3.22 2.53-3.22 4.66 0 2.76 2.24 5 5 5 .71 0 1.39-.15 2-.42v14.42c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-2.21-1.79-4-4-4h-2v-14c0-2.21-1.79-4-4-4h-12c-2.21 0-4 1.79-4 4v32h20v-15h3v10c0 2.76 2.24 5 5 5s5-2.24 5-5v-19c0-1.38-.56-2.63-1.46-3.54zm-15.54 5.54h-12v-10h12v10zm12 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
												</svg>
											</p>
										) : null}
										{extraData && item?.data?.temp ? (
											<p
												className={
													"flex text-xs font-bold mt-[-5px] -my-0.5 " +
													getTempLevelColor(item?.data?.temp)
												}
											>
												{parseInt(item?.data?.temp)}°
											</p>
										) : null}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
};
