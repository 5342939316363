import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import { useEffect } from "react";
import { safeUserStorage } from "../helper/storage";

const PATHS_WITHOUT_NAVBAR: any = {
	"/login": true,
};

export default function Root() {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const USER: any = safeUserStorage();

		if (!USER) {
			navigate("/");
		}

		const PARSED_USER = JSON.parse(USER);

		if (new Date().getTime() > PARSED_USER.expires) {
			localStorage.removeItem("user");
			navigate("/", { replace: true, state: { expired: true } });
		}
	}, []);

	return (
		<div className="flex flex-1 flex-col md:flex-row w-full">
			{PATHS_WITHOUT_NAVBAR[location.pathname] ? null : <Navbar />}

			<div className="py-4 max-w-[120rem] w-full mx-auto flex flex-1 flex-col box-border px-4">
				{<Outlet />}
			</div>
		</div>
	);
}
