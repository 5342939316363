import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

import ErrorPage from "./routes/error";
import Root from "./routes/root";
import Login from "./routes/login";
import BookingHelper from "./routes/bookinghelper";
import BusParking from "./routes/busParking";
import Start from "./routes/start";
import InfoDisplays from "./routes/infoDisplays";
import Users from "./routes/users";
import Logout from "./routes/logout";
import User from "./routes/user";
import InfoDisplay from "./routes/infoDisplay";
import Display from "./routes/display";
import Pincode from "./routes/pincode";
import VehicleMap from "./routes/old/vehicleMap";
import RootFull from "./routes/rootFull";
import InfoPlaylists from "./routes/infoPlaylists";
import InfoPlaylist from "./routes/infoPlaylist";
import CreateTimeDistanceGraph from "./routes/createTimeDistanceGraph";
import ReplacementTrips from "./routes/replacementTrips";
import ReplacementTripOrderDetails from "./routes/replacementTripOrderDetails";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Login />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/display/:displayId",
		element: <Display />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/logout",
		element: <Logout />,
	},
	{
		path: "/",
		element: <Root />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "start",
				element: <Start />,
			},

			{
				path: "bookinghelper",
				element: <BookingHelper />,
			},
			{
				path: "createtimedistancegraph",
				element: <CreateTimeDistanceGraph />,
			},
			{
				path: "infoplaylists",
				element: <InfoPlaylists />,
			},
			{
				path: "infoplaylists/:playlistId",
				element: <InfoPlaylist />,
			},
			{
				path: "infodisplays",
				element: <InfoDisplays />,
			},
			{
				path: "infodisplays/:displayId",
				element: <InfoDisplay />,
			},
			{
				path: "replacementtrips",
				element: <ReplacementTrips />,
			},
			{
				path: "users",
				element: <Users />,
			},
			{
				path: "users/:username",
				element: <User />,
			},
			{
				path: "pincode",
				element: <Pincode />,
			},
			{
				path: "replacementtrips/:tripId",
				element: <ReplacementTripOrderDetails />,
			}
		],
	},
	{
		path: "/",
		element: <RootFull />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "vehiclemap",
				element: <VehicleMap />,
			},
			{
				path: "parking",
				element: <BusParking />,
			},
		],
	},
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
