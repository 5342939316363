import axios from "axios";
import { safeUserStorage } from "./storage";
import { VASSDUPP_URL, VASSDUPP_VERSION } from "../config";


const postman = axios.create({
	baseURL: VASSDUPP_URL,
	withCredentials: true,
	timeout: 0,
	headers: {
		"Content-type": "application/json",
		"Version-number": VASSDUPP_VERSION,
	},
});

postman.interceptors.request.use(async (config: any) => {
	const session: any = safeUserStorage();

	if (!session) return config;

	const TOKEN = JSON.parse(session).token;

	config.headers = {
		...config.headers,
		Authorization: `Bearer ${TOKEN}`,
	};

	return config;
});

postman.interceptors.response.use(
	(response) => response,
	function (error) {
		if (error?.response?.status === 401) {
			if (
				error?.response.config.url !== "/auth" ||
				error?.response.config.method !== "post"
			) {
				window.location.replace("/logout");
			}
		}

		return Promise.reject(error);
	}
);

export default postman;
