const TableHeader = (props: { items: { name: string; width: string }[] }) => {
	return (
		<div className="flex flex-row w-full items-center rounded bg-blue-50 px-4 py-3 select-none">
			{props.items.map((item, index) => (
				<p
					key={index}
					className={`font-bold text-sm text-blue-600 opacity-70 ${item.width}`}
				>
					{item.name}
				</p>
			))}
		</div>
	);
};

export default TableHeader;
