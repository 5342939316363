import { useState } from "react";
import { fetchUsers } from "../helper/network";
import Modal from "../components/Modal";
import Input from "../components/input";
import InputSelect from "../components/inputSelect";
import { Link } from "react-router-dom";
import TableHeader from "../components/TableHeader";
import { prettyTime } from "../helper/date";

const headers = [
	{ name: "Beställd avgångstid", width: "w-40" },
	{ name: "Typ", width: "w-24" },
	{ name: "Antal bussar", width: "w-32" },
	{ name: "Stationer", width: "w-48" },
	{ name: "Kommentar", width: "flex-1" },
	{ name: "Status", width: "w-40" },
];

const replacementTripsData = [
	{
		OrderTimestamp: "2024-12-19 08:15",
		Reason: "Tekniskt fel",
		TrainNumber: 2812,
		Type: "Pendel",
		BusesOrdered: 3,
		ScheduledDepartureTime: "2024-12-19 09:00",
		Stationer: "Vallentuna, Täby Kyrkby",
		Comment: "",
		RegistrationNumber: "ABC123, ABC123",
		BusesArrived: 2,
		FirstBusArrivalApprox: "2024-12-19 08:50",
		ETA: "2024-12-19 09:45",
		ATA: "2024-12-19 09:40",
		ATD: "2024-12-19 09:55",
		EndTime: "2024-12-19 12:00",
		Status: "Slutförd",
	},
	{
		OrderTimestamp: "2024-12-18 18:30",
		Reason: "Oväder",
		TrainNumber: 2716,
		Type: "Pendel",
		BusesOrdered: 5,
		ScheduledDepartureTime: "2024-12-18 19:15",
		Stationer: "Österskär, Täljö",
		Comment: "",
		RegistrationNumber: "XYZ987, XYZ987, XYZ987, XYZ987, XYZ987",
		BusesArrived: 5,
		FirstBusArrivalApprox: "2024-12-18 19:00",
		ETA: "2024-12-18 19:50",
		ATA: "2024-12-18 19:45",
		ATD: "2024-12-18 20:00",
		EndTime: "2024-12-18 21:30",
		Status: "Ej bekräftad",
	},
	{
		OrderTimestamp: "2024-12-17 14:00",
		Reason: "Spårarbete",
		TrainNumber: 2914,
		Type: "Pendel",
		BusesOrdered: 4,
		ScheduledDepartureTime: "2024-12-17 14:45",
		Stationer: "Djursholms Ösby, Mörby",
		Comment: "",
		RegistrationNumber: "LMN456, LMN456, LMN456, LMN456",
		BusesArrived: 4,
		FirstBusArrivalApprox: "2024-12-17 14:30",
		ETA: "2024-12-17 15:30",
		ATA: "2024-12-17 15:25",
		ATD: "2024-12-17 15:40",
		EndTime: "2024-12-17 17:00",
		Status: "Inväntar tillsättning",
	},
];

const Trips = ({
	trips,
	isLoading,
	isError,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga hållplatser hittades</p>;
	}

	return trips.map((trip: any, i: number) => <ReplacementTripRow key={"dr-" + i} trip={trip} />);
};

const STATIONS = [
	{ name: "Danderyds sjukhus", lines: ["Linje 27", "Linje 28", "Linje 29"] },
	{ name: "Mörby station", lines: ["Linje 27", "Linje 28", "Linje 29"] },
	{ name: "Djursholms Ösby", lines: ["Linje 27", "Linje 28", "Linje 29"] },
	{ name: "Roslags Näsby", lines: ["Linje 27", "Linje 28"] },
	{ name: "Tibble", lines: ["Linje 27"] },
	{ name: "Ensta", lines: ["Linje 27"] },
	{ name: "Visinge", lines: ["Linje 27"] },
	{ name: "Täby kyrkby", lines: ["Linje 27"] },
	{ name: "Kragstalund", lines: ["Linje 27"] },
	{ name: "Bällsta", lines: ["Linje 27"] },
	{ name: "Vallentuna", lines: ["Linje 27"] },
	{ name: "Ormsta", lines: ["Linje 27"] },
	{ name: "Molnby", lines: ["Linje 27"] },
	{ name: "Lindholmen", lines: ["Linje 27"] },
	{ name: "Frösunda", lines: ["Linje 27"] },
	{ name: "Ekskogen", lines: ["Linje 27"] },
	{ name: "Kårsta", lines: ["Linje 27"] },
	{ name: "Bråvallavägen", lines: ["Linje 28"] },
	{ name: "Djursholms Ekeby", lines: ["Linje 28"] },
	{ name: "Enebyberg", lines: ["Linje 28"] },
	{ name: "Täby centrum", lines: ["Linje 28"] },
	{ name: "Galoppfältet", lines: ["Linje 28"] },
	{ name: "Viggbyholm", lines: ["Linje 28"] },
	{ name: "Hägernäs", lines: ["Linje 28"] },
	{ name: "Arninge", lines: ["Linje 28"] },
	{ name: "Rydbo", lines: ["Linje 28"] },
	{ name: "Täljö", lines: ["Linje 28"] },
	{ name: "Åkers Runö", lines: ["Linje 28"] },
	{ name: "Åkersberga", lines: ["Linje 28"] },
	{ name: "Tunagård", lines: ["Linje 28"] },
	{ name: "Österskär", lines: ["Linje 28"] },
	{ name: "Vendevägen", lines: ["Linje 29"] },
	{ name: "Östberga", lines: ["Linje 29"] },
	{ name: "Altorp", lines: ["Linje 29"] },
	{ name: "Lahäll", lines: ["Linje 29"] },
	{ name: "Näsby alle", lines: ["Linje 29"] },
	{ name: "Näsbypark", lines: ["Linje 29"] },
];

const LINES = ["L27", "L28", "L29"];

const stationsKeyed = {};

const reasonsData = ["Spårarbete", "Tekniskt fel", "Oväder"];

const ORDER_TYPES = ["Pendel", "Enkel"];

interface ReplacementTripType {
	OrderTimestamp: string;
	Reason: string;
	TrainNumber: number;
	Type: string;
	BusesOrdered: number;
	ScheduledDepartureTime: string;
	Stationer: string;
	Comment: string;
	RegistrationNumber: string;
	BusesArrived: number;
	FirstBusArrivalApprox: string;
	ETA: string;
	ATA: string;
	ATD: string;
	EndTime: string;
	Status: string;
}

function ReplacementTripRow({
	trip: {
		OrderTimestamp,
		Reason,
		TrainNumber,
		Type,
		BusesOrdered,
		ScheduledDepartureTime,
		Stationer,
		Comment,
		BusesArrived,
		FirstBusArrivalApprox,
		ETA,
		ATA,
		ATD,
		EndTime,
		Status,
	},
}: {
	trip: ReplacementTripType;
}) {
	const currentDate = new Date().getTime();
	const orderDate = new Date(OrderTimestamp).getTime();
	const end = new Date(EndTime).getTime();

	return (
		<Link
			to={"/replacementtrips/" + 32}
			className="flex flex-row w-full items-center px-4 py-2 border-b hover:bg-stone-50 ease-out transition-all"
		>
			<p className="font-semibold text-xs w-40">
				{prettyTime(parseInt(ScheduledDepartureTime))}
			</p>
			<p className="font-semibold text-xs w-24">{Type}</p>
			<p className="font-semibold text-xs w-24">{BusesOrdered}</p>
			<p className="font-semibold text-xs w-40">{Stationer}</p>
			<p className="font-semibold text-xs flex-1">{Comment}</p>
			<p className="px-2 py-1 min-w-40 bg-yellow-600 whitespace-nowrap text-center font-medium text-xs">
				{Status}
			</p>
		</Link>
	);
}

export default function ReplacementTrips() {
	const { data, mutate, isLoading, isError } = fetchUsers();
	const [showModal, setModal] = useState(false);

	const [fakeData, setFakeData] = useState<any>(replacementTripsData);

	// States for all inputs
	const [orderTimestamp, setOrderTimestamp] = useState("");
	const [reason, setReason] = useState("");
	const [trainNumber, setTrainNumber] = useState("");
	const [type, setType] = useState("");
	const [busesOrdered, setBusesOrdered] = useState("");
	const [scheduledDepartureTime, setScheduledDepartureTime] = useState("");
	const [fromStation, setFromStation] = useState("");
	const [toStation, setToStation] = useState("");
	const [viaStations, setViaStations] = useState([]);
	const [orderedBusesCount, setOrderedBusesCount] = useState("");
	const [comment, setComment] = useState("");
	const [registrationNumber, setRegistrationNumber] = useState("");
	const [busesArrived, setBusesArrived] = useState("");
	const [firstBusArrivalApprox, setFirstBusArrivalApprox] = useState("");
	const [eta, setEta] = useState("");
	const [ata, setAta] = useState("");
	const [atd, setAtd] = useState("");
	const [endTime, setEndTime] = useState("");

	// Handlers for form submission and cancel
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		// Add logic to handle form submission

		// add to fakeData

		const newTrip = {
			OrderTimestamp: new Date().toISOString(),
			Reason: reason,
			TrainNumber: trainNumber,
			Type: type,
			BusesOrdered: busesOrdered,
			ScheduledDepartureTime: scheduledDepartureTime,
			FromStation: fromStation,
			ToStation: toStation,
			OrderedBusesCount: orderedBusesCount,
			ViaStations: viaStations.join(", "),
			Comment: comment,
			RegistrationNumber: registrationNumber,
			BusesArrived: busesArrived,
			FirstBusArrivalApprox: firstBusArrivalApprox,
			ETA: eta,
			ATA: ata,
			ATD: atd,
			EndTime: endTime,
		};

		setModal(false);
		setFakeData([...fakeData, newTrip]);
	};

	const handleCancel = () => {
		// Reset all states
		setOrderTimestamp("");
		setReason("");
		setTrainNumber("");
		setType("");
		setBusesOrdered("");
		setScheduledDepartureTime("");
		setFromStation("");
		setToStation("");
		setOrderedBusesCount("");
		setViaStations([]);
		setComment("");
		setRegistrationNumber("");
		setBusesArrived("");
		setFirstBusArrivalApprox("");
		setEta("");
		setAta("");
		setAtd("");
		setEndTime("");
		setModal(false);
	};

	const handleSetStartStop = (stationIndex: number) => {
		const station = STATIONS[stationIndex];

		console.log(station);
	};

	return (
		<div className="flex flex-1 flex-col">
			<Modal show={showModal} closeModal={() => setModal(false)}>
				<form
					className="flex flex-col justify-between w-full h-full items-center py-12 px-20"
					onSubmit={handleSubmit}
				>
					<div className="flex flex-col items-center mb-6">
						<h1 className="font-medium text-xl">Skapa ersättningstur</h1>
						<p className="mt-2 text-slate-500 text-sm">
							Fyll i fälten nedan för att skapa en ersättningstur
						</p>
					</div>
					<div className="relative select-none w-full">
						<InputSelect
							placeholder="Typ av beställning"
							value={type}
							onChange={setType}
							items={ORDER_TYPES.map((type) => [type, type])}
						/>
					</div>
					<div className="mt-6 w-full">
						<Input
							placeholder="Antal bussar"
							value={busesOrdered}
							type="time"
							onChange={setBusesOrdered}
						/>
					</div>
					<div className="relative select-none w-full">
						<InputSelect
							placeholder="Från station"
							value={fromStation}
							onChange={setFromStation}
							items={STATIONS.map((station, i) => [String(i), station.name])}
						/>
					</div>
					<div className="relative select-none w-full">
						<InputSelect
							placeholder="Till station"
							value={toStation}
							onChange={setToStation}
							items={STATIONS.map((station, i) => [String(i), station.name])}
						/>
					</div>
					{/* {fromStation !== "" ? (
						<div className="w-full">
							<div className="mt-6 w-full">
								<select
									className="border rounded w-full p-2 min-h-96"
									multiple
									value={viaStations}
									onChange={(e) =>
										setViaStations(
											Array.from(
												e.target.selectedOptions,
												(option) => option.value
											)
										)
									}
								>
									{STATIONS[line].map((station) => (
										<option key={station.name} value={station.name}>
											{station.name}
										</option>
									))}
								</select>
							</div>
						</div>
					) : null} */}

					<div className="mt-6 w-full">
						<Input
							placeholder="Beställd avgångstid"
							value={scheduledDepartureTime}
							onChange={setScheduledDepartureTime}
						/>
					</div>

					<div className="mt-6 w-full">
						<Input placeholder="Tågnr" value={trainNumber} onChange={setTrainNumber} />
					</div>
					<div className="mt-6 w-full">
						<InputSelect
							value={reason}
							onChange={setReason}
							placeholder="Orsak"
							items={reasonsData.map((reason) => [reason, reason])}
						/>
					</div>
					<div className="mt-6 w-full">
						<Input placeholder="Kommentar" value={comment} onChange={setComment} />
					</div>

					<div className="flex flex-row mt-6 justify-center items-center">
						<div
							className="bg-slate-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:text-black"
							onClick={() => {
								handleCancel();
							}}
						>
							Avbryt
						</div>
						<input
							type="submit"
							className="bg-blue-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 ml-6 dark:text-black dark:bg-blue-400"
							value="Skapa beställning"
						/>
					</div>
				</form>
			</Modal>
			<h1 className="font-bold text-2xl mt-12 mb-3">Ersättningstrafik</h1>
			<div className="flex justify-end mb-4">
				<div
					className={
						"bg-blue-200 self-start text-sm px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700 "
					}
					onClick={() => setModal(true)}
				>
					Skapa beställning
				</div>
			</div>
			<div className="flex flex-1 flex-col w-full">
				<TableHeader items={headers} />
				<Trips trips={fakeData} isLoading={isLoading} isError={isError} />
			</div>
		</div>
	);
}
